import { Component, Vue } from 'vue-property-decorator';
import template from './Create.Template.vue';
import EditOrCreate from '../EditOrCreate.Template.vue';

import { TemplateOperation } from '@/models';

@Component({
    ...template,
    name: 'TemplateOperationsCreate',
    components: {
        EditOrCreate,
    },
})
export default class TemplateOperationsCreate extends Vue {
    public templateOperation: TemplateOperation = new TemplateOperation();
}

